import { MDXProvider } from "@mdx-js/react"
import React, { useEffect } from "react"
import Layout from "../components/Layout"
// import * as DesignSystem from "your-design-system"

export default function MdxLayout({ children,pageContext:{frontmatter} }) {
    useEffect(()=>{
        // console.log(frontmatter)
    },[])
    return (
        <Layout location="Industry News">
            <section className="cw-bg-bluegradient w-100 h-44 text-center mb-10">
                <div className="font-bold text-white text-5xl align-middle py-14 ">Industry news</div>
            </section>
            <div className="" style={{ margin: `0 auto`, maxWidth: 1000, padding: `0 1rem` }}>
                <MDXProvider
                components={{
                    p: props => <p {...props} className="pb-5" />,
                    a: props => <a {...props} target="_blank" className="text-blue-500 underline" />,
                    h2: props => <h2 {...props} className="py-2 text-xl" />
                }}
                > 
                <div className='mb-10'><a href='/industry-news' className='text-blue-500 font-semibold'>Industry News</a> / <span className='capitalize'>{frontmatter.title}</span></div>
                <h1 className="text-2xl font-semibold mb-5 text-left"> {frontmatter.title}</h1>
                {children}
                </MDXProvider>
            </div>
        </Layout>
    )
}